import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { BaseView } from "../../../base.view";
import { DevicesWidth } from "../../../../models/enums/devices.enum";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrl: "./header.component.scss",
})
export class HeaderComponent extends BaseView implements OnInit {
    isSidebarActive = false;
    isSticky = false;
    isBellowHeader = false;
    HEADER_HEIGHT = 77;
    isHeaderActive: boolean;

    ngOnInit(): void {
        this.onWindowResize();

        this.setSidebarOnLinkClick();
    }

    showHideSidebar(): void {
        if (this.isSidebarActive) {
            this.hideSidebar();
        } else {
            this.showSidebar();
        }
    }

    private setSidebarOnLinkClick() {
        if (this.isHeaderActive) {
            const navLinks = document.getElementsByClassName("nav-link");
            for (let i = 0; i < navLinks.length; i++) {
                navLinks[i].addEventListener("click", () => {
                    this.hideSidebar();
                });
            }
        }
    }

    @HostListener("window:resize", [])
    private onWindowResize(): void {
        this.HEADER_HEIGHT = document.getElementById("header").offsetHeight;
        console.log(this.HEADER_HEIGHT);

        this.isHeaderActive = DevicesWidth.DESKTOP.isEqualOrSmaller();
        this.setSidebarOnLinkClick();
    }

    @HostListener("window:scroll", [])
    private onWindowScroll(): void {
        if (this.isHeaderActive) {
            this.isBellowHeader = window.pageYOffset > this.HEADER_HEIGHT;
            this.isSticky = window.pageYOffset > this.HEADER_HEIGHT * 2;
        } else {
            this.isBellowHeader = false;
            this.isSticky = false;
        }
    }

    private hideSidebar() {
        this.isSidebarActive = false;
        document.getElementById("sidebar-backdrop").classList.add("d-none");
        document.getElementById("sidebar").classList.remove("active");
        document.body.style.removeProperty("overflow");
    }

    private showSidebar() {
        this.isSidebarActive = true;
        document.getElementById("sidebar-backdrop").classList.remove("d-none");
        document.getElementById("sidebar").classList.add("active");
        document.body.style.overflow = "hidden";
    }
}

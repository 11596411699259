import { Component, OnInit } from "@angular/core";
import { BaseView } from "../../../base.view";
import { BlogService } from "../../../../services/blog.service";

@Component({
    selector: "app-home-section-blog",
    templateUrl: "./home-section-blog.component.html",
    styles: ``,
})
export class HomeSectionBlogComponent extends BaseView implements OnInit {
    blogPosts: any[] = [];
    constructor(private readonly blogService: BlogService) {
        super();
    }

    ngOnInit() {
        this.blogService.getPosts().subscribe((result: any[]) => {
            console.log(result);
            this.blogPosts = result.takeFirst(3);
        });
    }
}

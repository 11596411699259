<div class="card project-card">
    <div class="row no-gutters">
        <div class="col-12 col-xl-5 card-img-holder">
            <img src="https://placehold.co/220x300" class="card-img" alt="image" />
        </div>
        <div class="col-12 col-xl-7">
            <div class="card-body">
                <h5 class="card-title">
                    <!-- <a role="button" class="theme-link" [routerLink]="URLS.PATHS.PORTFOLIO.PROJECT_DETAILS()"
                        >Título do Projeto</a
                    > -->
                    <a role="button" class="theme-link">Título do Projeto</a>
                </h5>
                <p class="card-text">
                    Site de casamento da minha cunhada, com informações do casal, da cerimônia, da história deles, confirmação de
                    presença e lista de presentes virtual.
                </p>
                <p>
                    <span class="badge rounded-pill text-bg-primary me-2">CLT</span>
                    <span class="badge rounded-pill text-bg-danger">Fullstack</span>
                </p>
                <p class="card-text"><small class="text-muted">Client: Google</small></p>
            </div>
        </div>
    </div>
    <div class="link-mask">
        <!-- <a class="link-mask-link" [routerLink]="URLS.PATHS.PORTFOLIO.PROJECT_DETAILS()" role="button"></a> -->
        <a class="link-mask-link" role="button"></a>
        <div class="link-mask-text">
            <!-- <a class="btn btn-secondary" [routerLink]="URLS.PATHS.PORTFOLIO.PROJECT_DETAILS()" role="button"> -->
            <a class="btn btn-secondary" role="button"> <i class="fas fa-eye me-2"></i> Ver detalhes </a>
        </div>
    </div>
    <!--//link-mask-->
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'app-printable-resume',
  templateUrl: './printable-resume.component.html',
  styles: ``
})
export class PrintableResumeComponent {

}

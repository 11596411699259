import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { NgModule, SecurityContext } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app";
import { AppRoutingModule } from "./app.routes";
import { InputTextComponent } from "./views/components/atoms/input-text/input-text.component";
import { HomeSectionFeaturedProjectsComponent } from "./views/components/organisms/home-section-featured-projects/home-section-featured-projects.component";
import { HomeSectionHeroComponent } from "./views/components/organisms/home-section-hero/home-section-hero.component";
import { HomeSectionSkillsComponent } from "./views/components/organisms/home-section-skills/home-section-skills.component";
import { HomeSectionTestimonialsComponent } from "./views/components/organisms/home-section-testimonials/home-section-testimonials.component";
import { SidebarComponent } from "./views/components/organisms/sidebar/sidebar.component";

import { MarkdownModule } from "ngx-markdown";
import { InputTextAreaComponent } from "./views/components/atoms/input-text-area/input-text-area.component";
import { SelectSingleChoiceComponent } from "./views/components/atoms/select-single-choice/select-single-choice.component";
import { CardBlogPostComponent } from "./views/components/molecules/card-blog-post/card-blog-post.component";
import { CardFeaturedProjectComponent } from "./views/components/molecules/card-featured-project/card-featured-project.component";
import { CardTestimonialComponent } from "./views/components/molecules/card-testimonial/card-testimonial.component";
import { ProjectMetricComponent } from "./views/components/molecules/project-metric/project-metric.component";
import { HomeSectionBlogComponent } from "./views/components/organisms/home-section-blog/home-section-blog.component";

import * as dayjs from "dayjs";
import "dayjs/locale/pt-br";
import * as duration from "dayjs/plugin/duration";
import "./extensions/array.extension";
import "./extensions/string.extension";
import { HeaderComponent } from "./views/components/molecules/header/header.component";
import { MaintenanceComponent } from "./views/components/organisms/maintenance/maintenance.component";
import { DrivePage } from "./views/pages/drive/drive.page";
import { WhatsappPage } from "./views/pages/whatsapp/whatsapp.page";
import { BlogPostPage } from "./views/pages/dev/main/blog-post/blog-post.page";
import { BlogPage } from "./views/pages/dev/main/blog/blog.page";
import { ContactPage } from "./views/pages/dev/main/contact/contact.page";
import { HomePage } from "./views/pages/dev/main/home/home.page";
import { NowPage } from "./views/pages/dev/main/now/now.page";
import { PortfolioPage } from "./views/pages/dev/main/portfolio/portfolio.page";
import { PricingAndServicesPage } from "./views/pages/dev/main/pricing-and-services/pricing-and-services.page";
import { ProjectDetailsPage } from "./views/pages/dev/main/project-details/project-details.page";
import { ResumePage } from "./views/pages/dev/main/resume/resume.page";
import { DevMainComponent } from "./views/pages/dev/main/dev-main.component";
import { PrintableResumeComponent } from "./views/pages/dev/printable-resume/printable-resume.component";
import { ResumeComponent } from "./views/components/organisms/resume/resume.component";
import { SosPage } from "./views/pages/sos/sos.page";

registerLocaleData(localePt);

dayjs.extend(duration);
dayjs.locale("pt-br");

@NgModule({
    declarations: [
        AppComponent,
        HomePage,
        PricingAndServicesPage,
        ResumePage,
        ContactPage,
        HomeSectionHeroComponent,
        HomeSectionSkillsComponent,
        HomeSectionTestimonialsComponent,
        SidebarComponent,
        HomeSectionFeaturedProjectsComponent,
        InputTextComponent,
        InputTextAreaComponent,
        SelectSingleChoiceComponent,
        CardFeaturedProjectComponent,
        PortfolioPage,
        CardBlogPostComponent,
        BlogPage,
        HomeSectionBlogComponent,
        NowPage,
        ProjectDetailsPage,
        ProjectMetricComponent,
        CardTestimonialComponent,
        BlogPostPage,
        WhatsappPage,
        MaintenanceComponent,
        HeaderComponent,
        DrivePage,
        DevMainComponent,
        PrintableResumeComponent,
        ResumeComponent,
        SosPage,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AppRoutingModule,
        CarouselModule,
        NgbModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgxSpinnerModule,
        MarkdownModule.forRoot({
            sanitize: SecurityContext.NONE,
        }),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: "toast-top-center",
            preventDuplicates: true,
            progressBar: true,
        }),
    ],
    providers: [provideNgxMask()],
    bootstrap: [AppComponent],
})
export class AppModule {}

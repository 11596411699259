import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class BlogService {
    // TODO: cachear os resultados, com expiração diária
    constructor(private readonly http: HttpClient) {}

    getPosts(): Observable<any> {
        return this.http.get("http://localhost:3000/api/blog");
    }
}

import { Component, OnInit } from "@angular/core";
import { BaseView } from "../../../../base.view";
import { BlogService } from "../../../../../services/blog.service";

@Component({
    selector: "blog-page",
    templateUrl: "./blog.page.html",
})
export class BlogPage extends BaseView implements OnInit {
    blogPosts: any[] = [];
    constructor(private readonly blogService: BlogService) {
        super();
    }

    ngOnInit() {
        this.blogService.getPosts().subscribe((result) => {
            console.log(result);
            this.blogPosts = result;
        });
    }
}

/**
 * Colocar uma sidebar no blog:
    - Campo de pesquisa por palavra-chave
    - Lista de categorias
    - Lista dos 5 posts mais acessados
    - Campo p/ subscrição por email 
 */

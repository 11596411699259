import { Component } from "@angular/core";
import { BasePageView } from "../../../base-page.view";

@Component({
    selector: "pricing-and-services-page",
    templateUrl: "./pricing-and-services.page.html",
    styles: ``,
})
export class PricingAndServicesPage extends BasePageView {
    items = ["First", "Second", "Third"];
}

import { Injectable } from "@angular/core";
import * as dayjs from "dayjs";

@Injectable({
    providedIn: "root",
})
export class DateService {
    constructor() {}

    ageToString(date: Date, onDate: Date = new Date(), short: boolean = false, includeRelativePrefix?: boolean): string {
        try {
            date = dayjs(date).toDate();
            const age = this.calculateAge(date, onDate);

            let ageString = "";

            if (age.days == 0 && age.months == 0 && age.years == 0) {
                return "hoje";
            }

            if (includeRelativePrefix) {
                const prefix = date.getTime() < onDate.getTime() ? "faz" : "daqui a";
                ageString = `${prefix.trim()} ${short ? "± " : ""}${ageString}`;
            }

            if (age.years > 0) {
                ageString += `${age.years} ${age.years > 1 ? "anos" : "ano"}`;
                if (short) {
                    return ageString;
                }
                ageString += `${age.months && age.days ? ", " : age.months || age.days ? " e " : ""}`;
            }
            if (age.months > 0) {
                ageString += `${age.months} ${age.months > 1 ? "meses" : "mês"}`;
                if (short) {
                    return ageString;
                }
                ageString += `${age.days ? " e " : ""}`;
            }
            if (age.days > 0) {
                ageString += `${age.days} ${age.days > 1 ? "dias" : "dia"}`;
            }

            return ageString;
        } catch (error) {
            return "";
        }
    }

    calculateAge(date: Date, onDate: Date = new Date()): { years: number; months: number; days: number } {
        const afterDate = date.getTime() > onDate.getTime() ? date : onDate;
        const beforeDate = date.getTime() < onDate.getTime() ? date : onDate;

        let years = afterDate.getFullYear() - beforeDate.getFullYear();
        let months = afterDate.getMonth() - beforeDate.getMonth();
        let days = afterDate.getDate() - beforeDate.getDate();

        if (months < 0 || (months === 0 && afterDate.getDate() < beforeDate.getDate())) {
            years--;
            months += 12;
        }

        if (days < 0) {
            const lastMonth = new Date(afterDate.getFullYear(), afterDate.getMonth() - 1, 0);
            days += lastMonth.getDate();
            months--;
        }

        return { years, months, days };
    }

    formatTwoDates(firstDate: Date, secondDate: Date): string {
        const first = dayjs(firstDate);
        const second = dayjs(secondDate);
        if (firstDate.getMonth() === secondDate.getMonth() && firstDate.getFullYear() === secondDate.getFullYear()) {
            return `${first.format("DD")} e ${second.format("DD")} de ${first.format("MMMM")} de ${firstDate.getFullYear()}`;
        } else if (firstDate.getFullYear() === secondDate.getFullYear()) {
            return `${first.format("DD")} de ${first.format("MMMM")} e ${second.format("DD")} de ${second.format(
                "MMMM"
            )} de ${firstDate.getFullYear()}`;
        } else {
            return `${first.format("DD")} de ${first.format("MMMM")} de ${firstDate.getFullYear()} e ${second.format(
                "DD"
            )} de ${second.format("MMMM")} de ${secondDate.getFullYear()}`;
        }
    }
}

import { HttpClient } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import { NotificationService } from "../../../../../services/notification.service";
import { BaseValidator } from "../../../../../services/validators/base.validator";
import { NameValidator } from "../../../../../services/validators/name.validator";
import { FormStateEnum } from "../../../../base-form.view";
import { InputTextAreaComponent } from "../../../../components/atoms/input-text-area/input-text-area.component";
import { NameInputTextModifier } from "../../../../components/atoms/input-text/input-text-modifiers";
import { InputTextComponent } from "../../../../components/atoms/input-text/input-text.component";
import {
    SelectSingleChoiceComponent,
    SelectOption,
} from "../../../../components/atoms/select-single-choice/select-single-choice.component";
import { BasePageFormView } from "../../../base-page-form.view";
import { EmailValidator } from "../../../../../services/validators/email.validator";

@Component({
    selector: "contact-page",
    templateUrl: "./contact.page.html",
    styles: ``,
})
export class ContactPage extends BasePageFormView {
    @ViewChild("inputName") inputNameComponent: InputTextComponent;
    @ViewChild("inputEmail") inputEmailComponent: InputTextComponent;
    @ViewChild("inputPhone") inputPhoneComponent: InputTextComponent;
    @ViewChild("selectReason") selectReasonComponent: SelectSingleChoiceComponent;
    @ViewChild("textAreaMessage") inputMessageComponent: InputTextAreaComponent;

    PHONE_MASK = BaseValidator.MASKS.PHONE;
    contactReasonSelectOptions: SelectOption[] = [
        { value: "basic-site", text: "Pacote de serviços - Site estático" },
        { value: "site-and-blog", text: "Pacote de serviços - Site estático + Blog" },
        { value: "web-app", text: "Pacote de serviços - Web App" },
        { value: "freelance-project", text: "Projeto freelancer" },
        { value: "feedback", text: "Feedback" },
        { value: "not-sure", text: "Outro motivo" },
    ];
    contactFormState = FormStateEnum.INITIAL;
    emailInputTextValidator = new EmailValidator();
    nameInputTextModifier = new NameInputTextModifier();
    nameInputTextValidator = new NameValidator(
        "O campo está incompleto. Por favor informe o Nome e Sobrenome com pelo menos 2 letras cada."
    );

    constructor(private readonly notifier: NotificationService, private readonly http: HttpClient) {
        super();
    }

    onSubmit(): void {
        if (this.isFormValid()) {
            this.contactFormState = FormStateEnum.SUBMITTED_LOADING;

            const dto = this.extractDtoFromForm();

            this.http.post("https://formspree.io/f/mgvwezlq", dto).subscribe({
                next: (result) => {
                    this.notifier.showSuccess("Mensagem enviada! Aguarde e em breve receberá uma resposta.");
                    this.contactFormState = FormStateEnum.SUBMITTED_SUCCESSFULLY;
                },
                error: (error) => {
                    this.contactFormState = FormStateEnum.SUBMITION_FAILED;
                    this.notifier.showError(this.ERROR_MESSAGES.FORM_HAS_ERRORS());
                },
            });
        } else {
            this.contactFormState = FormStateEnum.SUBMITION_FAILED;
            this.notifier.showError(this.ERROR_MESSAGES.FORM_HAS_ERRORS("do formulário de contato"));
        }
    }

    private isFormValid(): boolean {
        let isValid = true;
        isValid = this.inputNameComponent.validate() && isValid;
        isValid = this.inputEmailComponent.validate() && isValid;
        isValid = this.inputPhoneComponent.validate() && isValid;
        isValid = this.selectReasonComponent.validate() && isValid;
        isValid = this.inputMessageComponent.validate() && isValid;
        return isValid;
    }

    private extractDtoFromForm(): any {
        return {
            name: this.inputNameComponent.getValue(),
            phone: this.inputPhoneComponent.getValue(),
            email: this.inputEmailComponent.getValue(),
            reason: this.contactReasonSelectOptions.find((e) => e.value === this.selectReasonComponent.getSelectedValue()).text,
            message: this.inputMessageComponent.getValue(),
            subject: "[Site pessoal]: Nova mensagem do form de contato.",
        };
    }
}

<section class="p-3 p-lg-5 bg-white">
    <div class="container text-center single-col-max-width">
        <h2 class="heading fw-bold mb-4">Serviços &amp; Orçamento</h2>
        <div class="intro">
            <p>
                Eu tenho +12 anos de experiência construindo software para web. Você pode dar uma olhada no meu
                <a role="button" [routerLink]="URLS.PATHS.DEV.MAIN.PORTFOLIO.ROOT()">portfólio</a> e no meu
                <a role="button" [routerLink]="URLS.PATHS.DEV.MAIN.RESUME()">currículo</a> para conhecer um pouco mais das minhas
                habilidades e experiência.
            </p>
            <p class="mb-0">
                Eu estou aceitando projetos freelancer tanto como back-end quanto como fullstack. Abaixo está uma lista dos
                serviços que eu ofereço. Sinta-se livre para
                <a role="button" [routerLink]="URLS.PATHS.DEV.MAIN.CONTACT()">entrar em contato</a> e solicitar um orçamento.
            </p>
        </div>
    </div>
    <!--//container-->
</section>

<section class="pricing-section p-lg-5 p-3">
    <div class="container single-col-max-width">
        <div class="card">
            <div class="card-header">
                <h3 class="text-center fw-bold">Pacote de Serviços</h3>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table id="" class="table table-bordered bg-white">
                        <thead>
                            <tr class="">
                                <th scope="col" class="p-3 bg-body-tertiary"></th>
                                <th scope="col" class="p-3 text-center text-primary bg-body-tertiary">Site estático</th>
                                <th scope="col" class="p-3 text-center text-primary bg-body-tertiary">Site estático + Blog</th>
                                <th scope="col" class="p-3 text-center text-primary bg-body-tertiary">Web App</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="pricing-price-row">
                                <th scope="row" class="bg-body-tertiary">Complexidade</th>
                                <td class=""><span class="price-number">Baixa</span></td>
                                <td class=""><span class="price-number">Média</span></td>
                                <td class=""><span class="price-number">Alta</span></td>
                            </tr>

                            <tr class="pricing-desc-row">
                                <th scope="row" class="bg-body-tertiary">Detalhes</th>
                                <td class="">
                                    <span class="">
                                        Um site estático tradicional com a quantidade páginas desejadas e um formulário de
                                        contato.
                                    </span>
                                </td>
                                <td class="">
                                    <span class="">
                                        Um site estático tradicional com um blog incluso. O Blog poderá ser em Wordpress ou algo
                                        mais simples.
                                    </span>
                                </td>
                                <td class="">
                                    <span class="">
                                        Um aplicativo web totalmente customizado, com autenticação via Google e Facebook e uma
                                        infinidade de funcionalidades.
                                    </span>
                                </td>
                            </tr>

                            <tr class="">
                                <th class="bg-body-tertiary" scope="row">
                                    Criação do Layout<br />
                                    <small class="feature-desc font-weight-normal text-muted">
                                        Prototipação do layout considerando a responsividade em todos os dispositivo.
                                    </small>
                                </th>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                            </tr>
                            <tr class="">
                                <th class="bg-body-tertiary" scope="row">
                                    Acompanhamento ao vivo
                                    <br />
                                    <small class="feature-desc font-weight-normal text-muted">
                                        Disponbilizo uma site temporário para você acompanhar a evolução incremental do
                                        desenvolvimento do produto
                                    </small>
                                </th>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                            </tr>
                            <tr class="">
                                <th class="bg-body-tertiary" scope="row">
                                    Garantia contra bugs
                                    <br />
                                    <small class="feature-desc font-weight-normal text-muted">
                                        Corrijo todos os bugs num período de 3 meses após a entrega
                                    </small>
                                </th>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                            </tr>
                            <tr class="">
                                <th class="bg-body-tertiary" scope="row">
                                    Registro de domínio e hospedagem
                                    <br />
                                    <small class="feature-desc font-weight-normal text-muted">
                                        Inclui colocar o site/app em produção.
                                    </small>
                                </th>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                            </tr>
                            <tr class="">
                                <th class="bg-body-tertiary" scope="row">Conteúdo dinâmico</th>
                                <td class="text-center"><span class="text-muted">–</span></td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                            </tr>
                            <tr class="">
                                <th class="bg-body-tertiary" scope="row">
                                    Ambiente de testes
                                    <br />
                                    <small class="feature-desc font-weight-normal text-muted">
                                        Disponbilizo um ambiente em que você poderá testar cada nova funcionalidades a medida em
                                        que são desenvolvidas
                                    </small>
                                </th>
                                <td class="text-center"><span class="text-muted">–</span></td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                            </tr>
                            <tr class="">
                                <th class="bg-body-tertiary" scope="row">Funcionalidades customizadas</th>
                                <td class="text-center"><span class="text-muted">–</span></td>
                                <td class="text-center"><span class="text-muted">–</span></td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                            </tr>
                            <tr class="">
                                <th class="bg-body-tertiary" scope="row">Testes automatizados</th>
                                <td class="text-center"><span class="text-muted">–</span></td>
                                <td class="text-center"><span class="text-muted">–</span></td>
                                <td class="text-center">
                                    <i class="fas fa-check text-primary"></i>
                                </td>
                            </tr>
                            <tr class="">
                                <th class="bg-body-tertiary" scope="row">Tempo estimado de entrega</th>
                                <td class="text-center">de 15 dias a 1 mês</td>
                                <td class="text-center">de 1 a 3 meses</td>
                                <td class="text-center">a partir de 3 meses</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr class="pricing-cta-row">
                                <td class="bg-body-tertiary"></td>
                                <td class="text-center"><a class="btn btn-primary" href="#">Contratar</a></td>
                                <td class="text-center"><a class="btn btn-primary" href="#">Contratar</a></td>
                                <td class="text-center"><a class="btn btn-primary" href="#">Orçamento</a></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <!--//pricing-mobile-tabs-->

        <!--//pricing-table-->
    </div>
    <!--//container-->
</section>

<section class="p-lg-5 p-3">
    <div class="container single-col-max-width">
        <h3 class="text-center mb-4 font-weight-bold">FAQs</h3>
        <div class="text-center mb-5">Lista de perguntas mais frequentes</div>

        <div ngbAccordion [closeOthers]="true">
            <div ngbAccordionItem [collapsed]="true">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton class="fw-bold">Como é a forma de trabalho e de pagamento?</button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            Em geral, eu cobro por hora trabalhada. O cliente me dá uma visão geral do problema ou do produto que
                            deseja construir e eu proponho uma ou algumas possíveis soluções. A partir da solução acordada,
                            dividimos em pequenas entregas de valor por funcionalidade e eu faço uma estimativa de tempo de
                            desenvolvimento de cada funcionalidade e quantidade horas de trabalho necessárias. Daí então eu começo
                            a trabalhar no produto e todas as horas trabalhadas são contabilizadas. Ao final do desenvolvimento de
                            cada entrega, com o produto aprovado pelo cliente, as horas trabalhadas devem ser pagas antes de
                            colocar o produto no ar.
                        </ng-template>
                    </div>
                </div>
            </div>
            <div ngbAccordionItem [collapsed]="true">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton class="fw-bold">E quanto a criação de logomarcas?</button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            Eu não faço esse serviço. Eu aplico sua logomarca no site/app de todas as formas possíveis, mas quanto
                            a criação de uma, caso você não tenha, recomendo entrar em contato com um design para criar a sua.
                        </ng-template>
                    </div>
                </div>
            </div>
            <div ngbAccordionItem [collapsed]="true">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton class="fw-bold">Você faz vídeos e imagens promocionais do site/app?</button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            Não, não faço. Meus serviços se restrigem a ideação, prototipação, desenvolvimento, testes e
                            hospedagem/deploy do seu site/app.
                        </ng-template>
                    </div>
                </div>
            </div>
            <div ngbAccordionItem [collapsed]="true">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton class="fw-bold">Você faz aplicativos específicos para Android/iOS?</button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            No momento meu foco principal tem sido em aplicativos para web. Aplicativos para web, ou WebApps são
                            quase como apps nativos, sendo que eles rodam num navegador, como o Chrome ou Firefox. A não ser que
                            você tenha uma demanda extremamente específica, quase tudo dá pra ser feito com Web Apps. Além do
                            custo-benefício de criar, hospedar e manter um Web App ser muito mais barato que um aplicativo nativo,
                            você vai precisar de um especialista em Android e um em iOS para criar um app específico para cada uma
                            dessas plataformas.
                        </ng-template>
                    </div>
                </div>
            </div>
            <div ngbAccordionItem [collapsed]="true">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton class="fw-bold">
                        Em relação ao registro do domínio e hospedagem do site/app, como funciona?
                    </button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            Caso você não possua um domínio e/ou uma hospedagem para seu site/app, eu lhe darei toda a ajuda,
                            orientação e suporte necessários em todos os passos do processo para criarmos seu domínio e/ou sua
                            hospedagem. Todos os dados do seu domínio e hospedagem ficarão em seu nome. Ao final do
                            desenvolvimento do seu produto, eu solicitarei suas credenciais p/ que eu possa colocar o seu produto
                            no ar e a partir daí é com você.
                        </ng-template>
                    </div>
                </div>
            </div>
            <div ngbAccordionItem [collapsed]="true">
                <h2 ngbAccordionHeader>
                    <button ngbAccordionButton class="fw-bold">Como funciona o período de garantia?</button>
                </h2>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            Após a entrega do projeto, o produto entra no período de garantia por 3 meses a contar da data de
                            entrega, onde eu corrijo todo e qualquer erro ou problema reportado pelo cliente, de graça, durante
                            todo esse período. Nesse período não está incluso o desenvolvimento de nenhuma nova funcionalidade,
                            apenas correção daquilo que já existe. Após o final deste período, qualquer correção de bug será
                            cobrada por hora trabalhada.
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <!--//faq-accordion-->
    </div>
    <!--//container-->
</section>

<section class="bg-white py-5">
    <div class="container text-center single-col-max-width">
        <h2 class="heading fw-bold mb-4">Projeto: Doula.app</h2>
        <div class="project-intro">
            <p class="mb-0 lead">
                Aqui você encontrará mais informações sobre o cliente e mais detalhes do projeto, tais como: contexto do problema,
                desafios enfrentados, solução proposta, tecnologias utilizadas, resultados alcançados e feedbacks do(s)
                cliente(s).
            </p>
            <p></p>
        </div>
    </div>
    <!--//container-->
</section>

<section class="px-3 py-5 p-md-5">
    <div class="container">
        <div class="bg-white p-4">
            <div class="row g-4 g-lg-5">
                <div class="col-md-5 col-lg-4 col-xxl-3 d-none d-md-block">
                    <img class="project-thumb img-fluid rounded" src="https://placehold.co/250x400" alt="" />
                </div>
                <!--//col-->
                <div class="col-12 col-md-7 col-lg-8 col-xxl-9">
                    <div class="client-info">
                        <h3 class="client-name font-weight-bold mb-4">Client Name</h3>
                        <ul class="client-meta list-unstyled">
                            <li class="mb-2">
                                <i class="fas fa-industry fa-fw me-2"></i> <strong>Industry:</strong>
                                Tech
                            </li>
                            <li class="mb-2">
                                <i class="fas fa-users fa-fw me-2"></i> <strong>Size:</strong>
                                100+
                            </li>
                            <li class="mb-2">
                                <i class="fas fa-users fa-fw me-2"></i> <strong>Período:</strong>
                                10/05/2024 - 10/07/2024 (2 meses)
                            </li>
                            <li class="mb-2">
                                <strong><i class="fas fa-link fa-fw me-2"></i> Website:</strong>
                                <a class="theme-link" href="#">clientsite.com</a>
                            </li>
                        </ul>
                        <div class="client-bio mb-4">
                            Short description of the client and project requirements. Lorem ipsum dolor sit amet, consectetuer
                            adipiscing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Aenean commodo ligula eget dolor.
                        </div>
                        <h5 class="subheading mb-3">Project Requirements</h5>
                        <ul class="mb-0">
                            <li class="mb-2">Requirement lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
                            <li class="mb-2">Requirement donec pede justo, fringilla vel, aliquet nec.</li>
                            <li class="mb-2">Requirement phasellus ullamcorper ipsum rutrum nunc.</li>
                        </ul>
                    </div>
                    <!--//client-info-->
                </div>
                <!--//col-->
            </div>
            <!--//row-->
        </div>
        <!--//project-meta-->
        <div class="project-sections py-5">
            <div class="project-section mb-5">
                <h4 class="project-section-title mb-3">Project Overview</h4>
                <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
                    sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
                    nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel,
                    aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                    dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
                </p>
            </div>
            <!--//project-section-->

            <div class="project-section mb-5">
                <h4 class="project-section-title">The Challenge</h4>
                <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
                    sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
                    nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel,
                    aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                    dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
                </p>
            </div>
            <!--//project-section-->

            <div class="project-section mb-5">
                <h4 class="project-section-title">The Approach &amp; Solution</h4>
                <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
                    sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
                    nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel,
                    aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                    dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
                </p>
                <div class="row mt-5">
                    <div class="col-12 col-lg-6 mb-5">
                        <img class="img-fluid rounded" src="https://placehold.co/540x360" alt="image" />
                    </div>
                    <div class="col-12 col-lg-6 mb-5">
                        <img class="img-fluid rounded" src="https://placehold.co/540x360" alt="image" />
                    </div>
                </div>

                <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
                    sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
                    nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel,
                    aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                    dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
                </p>
            </div>
            <!--//project-section-->

            <div class="project-section mb-5">
                <h4 class="project-section-title mb-3">The Results</h4>

                <div class="metrics mb-4">
                    <div class="row">
                        <div class="metric col-6 col-xl-3 mb-3">
                            <app-project-metric></app-project-metric>
                        </div>
                        <div class="metric col-6 col-xl-3 mb-3">
                            <app-project-metric></app-project-metric>
                        </div>
                        <div class="metric col-6 col-xl-3 mb-3">
                            <app-project-metric></app-project-metric>
                        </div>
                        <div class="metric col-6 col-xl-3 mb-3">
                            <app-project-metric></app-project-metric>
                        </div>
                        <!--//metric-->
                        <!--//metric-->
                    </div>
                    <!--//row-->
                </div>
                <!--//metrics-->

                <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
                    sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
                    nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel,
                    aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam
                    dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
                </p>
            </div>
            <!--//project-section-->

            <div class="project-section mb-5">
                <h4 class="project-section-title">Client Testimonial</h4>
            </div>

            <app-card-testimonial></app-card-testimonial>
        </div>
    </div>
</section>

<section class="promo-section bg-white py-5 mb-5 text-center">
    <div class="container single-col-max-width">
        <h2 class="title">Quer minha ajuda nos seus projetos?</h2>
        <p>
            Se você está querendo tirar suas ideias do papel e colocá-la no mundo real ou se precisa de uma ajuda extra num
            projeto já existente, clica no botão aí embaixo e manda um Oi!
        </p>
        <div class="text-center">
            <a class="btn btn-primary" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.CONTACT()"
                ><i class="fas fa-paper-plane me-2"></i> Enviar mensagem</a
            >
        </div>
    </div>
    <!--//container-->
</section>

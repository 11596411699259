<section class="p-3 p-lg-5 bg-white">
    <div class="container text-center single-col-max-width">
        <h2 class="heading fw-bold mb-4">Currículo online</h2>
        <a
            class="btn btn-primary"
            href="https://drive.google.com/file/d/1n9In4tTgjUW9-v6QH3WEQu6l-luBy3uD/view?usp=sharing"
            target="_blank"
        >
            <i class="fas fa-file-pdf me-2"></i>Baixar versão em PDF
        </a>
    </div>
    <!--//container-->
</section>

<div class="container px-3 px-lg-5 py-5">
    <div class="shadow-lg">
        <app-resume></app-resume>
    </div>
</div>

<article class="blog-post px-3 py-5 p-md-5">
    <div class="container single-col-max-width">
        <header class="blog-post-header">
            <h2 class="title mb-2">Why Every Developer Should Have A Blog</h2>
            <div class="meta mb-3">
                <span class="date">Published 2 days ago</span><span class="time">5 min read</span
                ><span class=""><a class="text-link" href="#">4 comments</a></span>
            </div>
        </header>

        <div class="blog-post-body">
            <figure class="blog-banner">
                <a href="https://made4dev.com"><img class="img-fluid" src="https://placehold.co/800x400" alt="image" /></a>
                <figcaption class="mt-2 text-center meta">
                    Image Credit:
                    <a class="theme-link" href="https://made4dev.com?ref=devblog" target="_blank"
                        >made4dev.com (Premium Programming T-shirts)</a
                    >
                </figcaption>
            </figure>
            <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
                sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,
                vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede
                mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.
                Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.
            </p>

            <h3 class="mt-5 mb-3">Code Block Example</h3>
            <p>
                You can get more info at
                <a class="text-link" href="https://highlightjs.org/" target="_blank">https://highlightjs.org/</a>. Lorem ipsum
                dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque
                eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate
                eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis
                pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean
                leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.
            </p>
            <pre></pre>

            <h3 class="mt-5 mb-3">Typography</h3>
            <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
                sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            </p>
            <h5 class="my-3">Bullet Points:</h5>
            <ul class="mb-5">
                <li class="mb-2">Lorem ipsum dolor sit amet consectetuer.</li>
                <li class="mb-2">Aenean commodo ligula eget dolor.</li>
                <li class="mb-2">Aenean massa cum sociis natoque penatibus.</li>
            </ul>
            <ol class="mb-5">
                <li class="mb-2">Lorem ipsum dolor sit amet consectetuer.</li>
                <li class="mb-2">Aenean commodo ligula eget dolor.</li>
                <li class="mb-2">Aenean massa cum sociis natoque penatibus.</li>
            </ol>
            <h5 class="my-3">Quote Example:</h5>
            <blockquote class="blockquote m-lg-5 py-3 ps-4 px-lg-5">
                <p class="mb-2">
                    You might not think that programmers are artists, but programming is an extremely creative profession. It's
                    logic-based creativity.
                </p>
                <footer class="blockquote-footer mt-0">John Romero</footer>
            </blockquote>

            <h5 class="my-3">Table Example:</h5>
            <table class="table table-striped my-5">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>Mark</td>
                        <td>Otto</td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>Jacob</td>
                        <td>Thornton</td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>Larry</td>
                        <td>the Bird</td>
                    </tr>
                </tbody>
            </table>

            <h5 class="mb-3">Embed A Tweet:</h5>

            <div
                class="twitter-tweet twitter-tweet-rendered"
                style="display: flex; max-width: 550px; width: 100%; margin-top: 10px; margin-bottom: 10px"
            >
                <iframe
                    id="twitter-widget-0"
                    scrolling="no"
                    frameborder="0"
                    allowtransparency="true"
                    allowfullscreen="true"
                    class=""
                    title="X Post"
                    src="https://platform.twitter.com/embed/Tweet.html?dnt=false&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;frame=false&amp;hideCard=false&amp;hideThread=false&amp;id=926458505355235328&amp;lang=en&amp;origin=https%3A%2F%2Fthemes.3rdwavemedia.com%2Fdevcard%2Fbs5%2F2.2%2Fblog-post.html&amp;sessionId=bb5334b73bcd60c0931a1d01a80fbb34a45d1478&amp;theme=light&amp;widgetsVersion=2615f7e52b7e0%3A1702314776716&amp;width=550px"
                    style="position: static; visibility: visible; width: 550px; height: 369px; display: block; flex-grow: 1"
                    data-tweet-id="926458505355235328"
                ></iframe>
            </div>
            <script async="" src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

            <h3 class="mt-5 mb-3">Video Example</h3>
            <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
                sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
            </p>

            <div class="ratio ratio-16x9">
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/1nxSE0R27Gg"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen=""
                ></iframe>
            </div>
        </div>

        <div class="blog-comments-section"></div>
        <!--//blog-comments-section-->
    </div>
    <!--//container-->
</article>

<section class="bg-white py-5">
    <div class="container text-center single-col-max-width">
        <h2 class="heading fw-bold mb-4">Portfolio</h2>
        <div class="intro">
            <p>
                Aqui você encontrará detalhes de alguns dos projetos mais interessantes que já trabalhei na minha carreira, tanto
                nas empresas pelas quais passei quanto como freelancer e até mesmo projetos autônomos interessantes.
            </p>
            <p>
                Ao acessar os detalhes do projeto você encontrará uma breve descrição do projeto e do cliente, bem como o contexto
                do problema, a solução prospota, os desafios enfrentados, as tecnologias utilizadas, os resusltados alcançados e o
                feedback do cliente.
            </p>
        </div>
        <a class="btn btn-primary" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.CONTACT()"
            ><i class="fas fa-paper-plane me-2"></i>Entrar me contato</a
        >
    </div>
    <!--//container-->
</section>

<app-maintenance></app-maintenance>

<div class="form-group">
    <label [for]="inputId" class="form-label" *ngIf="label">
        {{ label }}
        <i
            class="fa-solid fa-circle-info"
            role="button"
            *ngIf="showInfoPopover"
            [ngbPopover]="popContent"
            [openDelay]="300"
            triggers="mouseenter:mouseleave"
        ></i>
        <ng-template #popContent>
            <ng-content></ng-content>
        </ng-template>
    </label>
    <div class="form-group mb-0 position-relative has-icon-right w-100">
        <input
            #inputField
            class="form-control"
            autocomplete="off"
            [type]="type"
            [name]="inputId"
            [id]="inputId"
            [placeholder]="placeholder"
            [mask]="mask"
            [dropSpecialCharacters]="false"
            [readOnly]="readOnly"
            [ngClass]="{ 'is-invalid': !valid && isValidated, readonly: readOnly }"
            (keyup)="onChange()"
            (input)="onInputChange($event)"
            [(ngModel)]="value"
        />
        <div class="form-control-icon" *ngIf="value && showClearButton" placement="top" ngbTooltip="Limpar">
            <a role="button" (click)="onClearInput()">
                <i class="fa-regular fa-circle-xmark fa-2x"></i>
            </a>
        </div>
        <div class="invalid-feedback" *ngIf="!valid && isValidated">
            <div>{{ errorMessage }}</div>
        </div>
    </div>
    <div class="form-text" *ngIf="helperText">{{ helperText }}</div>
</div>

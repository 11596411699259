<section class="testimonials-section p-3 p-lg-5">
    <div class="container">
        <h2 class="section-title font-weight-bold mb-5">Relatos</h2>

        <div class="testiomonial-carousel-container">
            <owl-carousel-o [options]="customOptions" class="testimonial-carousel">
                <ng-template carouselSlide class="owl-item">
                    <app-card-testimonial></app-card-testimonial>
                </ng-template>
                <ng-template carouselSlide class="owl-item">
                    <app-card-testimonial></app-card-testimonial>
                </ng-template>
                <ng-template carouselSlide class="owl-item">
                    <app-card-testimonial></app-card-testimonial>
                </ng-template>
            </owl-carousel-o>

            <!--//testimonial-carousel-->
        </div>
        <!--//testimonial-carousel-container-->
    </div>
    <!--//container-->
</section>

<section class="overview-section p-3 p-lg-5">
    <div class="container">
        <h2 class="section-title font-weight-bold mb-3">Principais competências</h2>
        <div class="section-intro mb-5">
            Já atuei em +{{ personalInfo.projects?.length }} projetos com as mais diversas tecnologias tanto como front-end quanto
            de back-end. Abaixo está uma visão geral das minhas principais habilidades técnicas e tecnologias que eu já utilizei.
            Quer saber mais sobre minha experiência? Dá uma olhada no meu
            <a class="text-link" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.RESUME()">currículo online</a> e em alguns dos
            projetos que já desenvolvi no meu
            <a class="text-link" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.PORTFOLIO.ROOT()">portfólio</a>.
        </div>
        <div class="row">
            <div class="mb-5 col-6 col-lg-4">
                <div class="item-inner">
                    <div class="item-icon mb-2">
                        <i class="fab fa-html5 fa-2x me-2"></i>
                        <i class="fab fa-css3-alt fa-2x me-2"></i>
                        <i class="fab fa-sass fa-2x me-2"></i>
                        <i class="fab fa-js-square fa-2x me-2"></i>
                    </div>
                    <h5 class="">HTML, CSS, SCSS e JS</h5>
                    <div class="text">
                        O básico de todo projeto Web. Tenho bastante fluência em HTML, CSS e JS. Desde que iniciei minha carreira,
                        utilizei-os em praticamente todos os projetos que já participei, em conjunto com alguns framworks CSS como
                        Bootstrap e Tailwind.
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="mb-5 col-6 col-lg-4">
                <div class="item-inner">
                    <div class="item-icon mb-2">
                        <i class="fab fa-angular me-2 fa-2x"></i>
                        <i class="fab fa-react me-2 fa-2x"></i>
                    </div>
                    <h5 class="">Angular &amp; React</h5>
                    <div class="item-desc">
                        Trabalho com Angular desde 2017 em diversos projetos, tanto pessoais quanto profissionais. É a tecnologia
                        front-end que tenho a maior preferência. Já em relação ao React, utilizei em 2 projetos nas empresas que
                        trabalhei.
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->

            <div class="mb-5 col-6 col-lg-4">
                <div class="item-inner">
                    <div class="item-icon mb-2">
                        <i class="fab fa-node-js fa-2x me-2"></i>
                        <img
                            src="https://seeklogo.com/images/N/nestjs-logo-09342F76C0-seeklogo.com.png"
                            class="align-top grayscale brand-icon"
                        />
                    </div>
                    <h5 class="">Node &amp; NestJS</h5>
                    <div class="item-desc">
                        É a tech stack que mais gosto de utilizar no back-end. Node com o framework NestJS é a minha primeira
                        escolha quando penso em iniciar algum novo projeto. Já utilizei em +5 projetos tanto pessoais quanto
                        profissionais.
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->

            <div class="mb-5 col-6 col-lg-4">
                <div class="item-inner">
                    <div class="item-icon mb-2">
                        <i class="fab fa-java fa-2x me-2"></i>
                    </div>
                    <h5 class="">Java</h5>
                    <div class="item-desc">
                        Aprendi a programar em Java na versão 6 e desde então utilizei intensivamente em diversos projetos
                        profissionais. Também já trabalhei nas versões v8 e v11. Atualmente atuo em projetos utilizando v17.
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="mb-5 col-6 col-lg-4">
                <div class="item-inner">
                    <div class="item-icon mb-2">
                        <img
                            class="me-2 grayscale align-top brand-icon"
                            src="https://seeklogo.com/images/M/mongodb-logo-655F7D542D-seeklogo.com.png"
                        />
                        <img
                            class="me-2 grayscale brand-icon"
                            src="https://seeklogo.com/images/P/postgresql-logo-5309879B58-seeklogo.com.png"
                        />
                    </div>
                    <h5 class="">MongoDB, PostgreSQL &amp; afins</h5>
                    <div class="item-desc">
                        Na grande maioria dos projetos das empresas por quais passei eu utilizei PostgreSQL. Fiz uso do MongoDB
                        nos projeto pessoais e freelancers. Já tive experiência com SQLServer e Oracle em alguns poucos momentos.
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <div class="mb-5 col-6 col-lg-4">
                <div class="item-inner">
                    <div class="item-icon mb-2">
                        <img
                            class="me-2 grayscale align-top brand-icon"
                            src="https://seeklogo.com/images/G/google-cloud-logo-ADE788217F-seeklogo.com.png"
                        />
                        <img
                            class="me-2 grayscale brand-icon"
                            src="https://seeklogo.com/images/D/docker-logo-CF97D0124B-seeklogo.com.png"
                        />
                        <img
                            class="me-2 grayscale brand-icon"
                            src="https://seeklogo.com/images/J/jest-logo-F9901EBBF7-seeklogo.com.png"
                        />
                    </div>
                    <h5 class="">DevOps &amp; Testes</h5>
                    <div class="item-desc">
                        Tenho experiência razoável com Docker e Google Cloud Platform no âmbito profissional. Inclusive hospedo
                        todos os meus projetos pessoais e freelancers no GCP. Tenho bastante experiência com TDD e testes
                        autoamtizados, especialmente no back-end, nos diversos níveis da pirâmide de testes, passando por testes
                        unitários, de contrato, de integração, de API e end-to-end.
                    </div>
                </div>
                <!--//item-inner-->
            </div>
        </div>
        <!--//row-->
        <div class="text-center py-3">
            <a class="btn btn-primary" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.PRICING_AND_SERVICES()">
                <i class="fas fa-arrow-alt-circle-right me-2"></i> Serviços &amp; Orçamento
            </a>
        </div>
    </div>
    <!--//container-->
</section>

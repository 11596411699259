import { Component } from "@angular/core";

@Component({
    selector: "now-page",
    templateUrl: "./now.page.html",
    styles: ``,
})
export class NowPage {
    md: any;

    onLoad(event): void {}

    onError(error): void {
        console.log(error);
    }
}

import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";

@Component({
    selector: "app-root",
    templateUrl: "./app.html",
    styleUrls: [],
})
export class AppComponent {
    title = "raphaelcomph-dev-website-frontend";
}

export class AppUrls {
    public static readonly PATHS = {
        DEV: {
            MAIN: {
                ROOT: (absolutePath = true) => `${absolutePath ? "/dev" : "dev"}`,
                HOME: (absolute: boolean = true) => (absolute ? `${this.PATHS.DEV.MAIN.ROOT(absolute)}/sobre` : "sobre"),
                NOW: (absolute: boolean = true) => (absolute ? `${this.PATHS.DEV.MAIN.ROOT(absolute)}/now` : "now"),
                PORTFOLIO: {
                    ROOT: (absolute: boolean = true) =>
                        absolute ? `${this.PATHS.DEV.MAIN.ROOT(absolute)}/portfolio` : "portfolio",
                },
                PRICING_AND_SERVICES: (absolute: boolean = true) =>
                    absolute ? `${this.PATHS.DEV.MAIN.ROOT(absolute)}/servicos` : "servicos",
                RESUME: (absolute: boolean = true) =>
                    absolute ? `${this.PATHS.DEV.MAIN.ROOT(absolute)}/curriculo` : "curriculo",
                BLOG: (absolute: boolean = true) => (absolute ? `${this.PATHS.DEV.MAIN.ROOT(absolute)}/blog` : "blog"),
                CONTACT: (absolute: boolean = true) => (absolute ? `${this.PATHS.DEV.MAIN.ROOT(absolute)}/contato` : "contato"),
            },
        },

        RESUME: (absolute: boolean = true) => (absolute ? `/curriculo` : "curriculo"),
        WHATSAPP: (absolutePath = true) => `${absolutePath ? "/" : ""}whatsapp`,
        DRIVE: (absolutePath = true) => `${absolutePath ? "/" : ""}drive`,
        SOS: (absolutePath = true) => `${absolutePath ? "/" : ""}sos`,
    };

    public static LINKS = {
        WHATSAPP: () => `https://wa.me/5581998212130`,
        GOOGLE_DRIVE: () => `https://drive.google.com/drive/folders/1LUElUNaJfpxSzGMQmypVyWUjLnd7ee1z?usp=drive_link`,
    };
}

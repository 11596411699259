import { Component, OnInit } from "@angular/core";
import { BaseView } from "../../../base.view";
import { DataFileService } from "../../../../services/data-file.service";
import { DateService } from "../../../../services/date.service";
import dayjs from "dayjs";

@Component({
    selector: "app-home-section-hero",
    templateUrl: "./home-section-hero.component.html",
    styles: ``,
})
export class HomeSectionHeroComponent extends BaseView implements OnInit {
    personalInfo: any = {};

    constructor(private readonly dataFileService: DataFileService, private readonly dateService: DateService) {
        super();
    }

    public get age(): number {
        return this.dateService.calculateAge(dayjs(this.personalInfo.birthDate).toDate()).years;
    }
    public get workingAge(): number {
        return this.dateService.calculateAge(dayjs(this.personalInfo.startWorkingDate).toDate()).years;
    }

    ngOnInit(): void {
        this.dataFileService.getPersonalInfo().subscribe((info) => {
            this.personalInfo = info;
        });
    }
}

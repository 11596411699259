export interface DeviceWidthEnum {
    key: string;
    minWidth: number;
    maxWidth: number;
}

export const DevicesWidth = {
    MOBILE_VERY_SMALL: {
        key: "MOBILE_VERY_SMALL",
        minWidth: 0,
        maxWidth: 575,
        isEqualOrSmaller: () => window.innerWidth <= DevicesWidth.MOBILE_VERY_SMALL.maxWidth,
    },
    MOBILE: {
        key: "MOBILE",
        minWidth: 576,
        maxWidth: 767,
        isEqualOrSmaller: () => window.innerWidth <= DevicesWidth.MOBILE.maxWidth,
    },
    TABLET: {
        key: "TABLET",
        minWidth: 768,
        maxWidth: 991,
        isEqualOrSmaller: () => window.innerWidth <= DevicesWidth.TABLET.maxWidth,
    },
    DESKTOP: {
        key: "DESKTOP",
        minWidth: 992,
        maxWidth: 1200,
        isEqualOrSmaller: () => window.innerWidth <= DevicesWidth.DESKTOP.maxWidth,
    },

    LARGE_DESKTOP: {
        key: "LARGE_DESKTOP",
        minWidth: 1200,
        maxWidth: 2400,
        isEqualOrSmaller: () => window.innerWidth <= DevicesWidth.LARGE_DESKTOP.maxWidth,
    },
};

<div class="card blog-post-card">
    <img class="card-img-top" src="{{ featureImageUrl }}" alt="image" />
    <div class="card-body">
        <h5 class="card-title">
            <!-- <a class="theme-link" [routerLink]="URLS.PATHS.DEV.MAIN.BLOG. BLOG_POST()">Top 3 JavaScript Frameworks</a> -->
            <a class="theme-link" role="button" href="{{ url }}" target="_blank">{{ title }}</a>
        </h5>
        <p class="card-text">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
            natoque penatibus et magnis dis parturient...
        </p>
        <!-- <p class="mb-0"><a class="text-link" [routerLink]="URLS.PATHS.DEV.MAIN.BLOG_POST()">Ler mais →</a></p> -->
        <p class="">
            <a class="text-link" role="button" href="{{ url }}" target="_blank"
                >Ler mais <i class="fa-solid fa-arrow-right"></i
            ></a>
        </p>
        <small class="d-block mb-2">
            <span class="badge rounded-pilly me-2" [ngStyle]="{ backgroundColor: tag.color }" *ngFor="let tag of tags">{{
                tag.name
            }}</span>
        </small>
        <small class="text-muted d-block">Publicado em {{ formatDate(publishedAt, "dddd, DD/MM/YYYY") }}</small>
        <!-- <small class="text-muted d-block">Atualizado há 2 dias</small> -->
    </div>
</div>

<section class="vh-100 d-flex align-items-center">
    <div class="align-self-center mx-auto py-8 px-sm-10 px-3 shadow-lg bg-white" style="z-index: 100">
        <div class="mx-auto p-sm-5 py-5">
            <div class="mb-4">
                <div class="text-center">
                    <h1 class="text-muted">Aguarde...</h1>
                </div>
            </div>

            <div class="text-center mb-2">
                <p>Você está sendo redirecionado meu whatsapp...</p>
            </div>
        </div>
    </div>
</section>

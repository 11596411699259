<header id="header" [ngStyle]="{ 'padding-top': isBellowHeader ? HEADER_HEIGHT + 'px' : '0px' }">
    <!-- <header id="header"> -->
    <nav class="bg-primary text-center py-3 d-xl-none" [ngClass]="{ 'sticky-on': isSticky, 'sticky-header': isBellowHeader }">
        <div class="row">
            <div class="col-2 text-start d-flex align-items-center">
                <a role="button" class="burger-btn d-block text-white ms-4" (click)="showHideSidebar()">
                    <i class="bi bi-justify fs-3"></i>
                </a>
            </div>
            <div class="col-8">
                <h1 class="mb-0">
                    <a class="no-text-decoration text-white" href="index.html">Raphael Soares</a>
                </h1>
            </div>
        </div>
    </nav>
</header>

<div id="sidebar-backdrop" class="sidebar-backdrop d-none" (click)="showHideSidebar()"></div>

<div class="item-inner me-4">
    <div class="quote-holder">
        <blockquote class="quote-content">
            Simon is a brilliant software engineer! Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
            ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis.
        </blockquote>
        <i class="fas fa-quote-left"></i>
    </div>
    <!--//quote-holder-->
    <div class="source-holder">
        <div class="source-profile">
            <img
                src="https://themes.3rdwavemedia.com/devcard/bs5/2.2/assets/images/clients/profile-2.png"
                alt="image"
                style="max-width: 60px"
            />
        </div>
        <div class="meta">
            <div class="name">Olivia White</div>
            <div class="info">Product Manager, Dropbox</div>
        </div>
    </div>
</div>

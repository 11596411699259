<section class="featured-section p-3 p-lg-5">
    <div class="container">
        <h2 class="section-title font-weight-bold mb-5">Projetos em destaque</h2>
        <div class="row">
            <div class="col-md-6 mb-2">
                <app-card-featured-project></app-card-featured-project>
            </div>
            <div class="col-md-6 mb-2">
                <app-card-featured-project></app-card-featured-project>
            </div>
            <div class="col-md-6 mb-2">
                <app-card-featured-project></app-card-featured-project>
            </div>
            <div class="col-md-6 mb-2">
                <app-card-featured-project></app-card-featured-project>
            </div>
        </div>
        <div class="text-center py-3">
            <a class="btn btn-primary" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.PORTFOLIO.ROOT()"
                ><i class="fas fa-arrow-alt-circle-right me-2"></i> Ver portfólio completo</a
            >
        </div>
    </div>
</section>

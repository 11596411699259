<section class="featured-section p-3 p-lg-5">
    <div class="container">
        <h2 class="section-title font-weight-bold mb-5">Últimos posts do blog</h2>
        <div class="row">
            <div class="col-md-4 mb-2" *ngFor="let blogPost of blogPosts">
                <app-card-blog-post
                    [title]="blogPost.title"
                    [featureImageUrl]="blogPost.featureImageUrl"
                    [url]="blogPost.url"
                    [publishedAt]="blogPost.publishedAt"
                    [tags]="blogPost.tags"
                ></app-card-blog-post>
            </div>
        </div>
        <div class="text-center py-3">
            <a class="btn btn-primary" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.BLOG()"
                ><i class="fas fa-arrow-alt-circle-right me-2"></i>
                Ir para o blog
            </a>
        </div>
    </div>
</section>

import { Component } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
    selector: "app-home-section-testimonials",
    templateUrl: "./home-section-testimonials.component.html",
    styleUrls: ["./home-section-testimonials.component.scss"],
})
export class HomeSectionTestimonialsComponent {
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplaySpeed: 1000,
        navSpeed: 2000,
        // navText: ["", ""],
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            740: {
                items: 3,
            },
            940: {
                items: 6,
            },
        },
    };
}

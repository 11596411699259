import { environment } from "../../environments/environment";
import { AppUrls } from "../app.urls";
import { ErrorMessages } from "../models/enums/error-messages.enum";
import dayjs from "dayjs";

export class BaseView {
    URLS = AppUrls;
    ERROR_MESSAGES = ErrorMessages;
    ENV = environment;
    locale: string = "pt-br";

    formatDate(value: Date, format: string = "DD/MM/YYYY"): string {
        return this.formatDateTime(value, format);
    }

    formatDateTime(value: Date, format: string = "DD/MM/YYYY HH:mm"): string {
        if (value != null && value != undefined) {
            return dayjs(value).locale(this.locale).format(format);
        }
        return `-`;
    }
}

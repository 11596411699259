<div id="sidebar" class="text-center">
    <div class="sidebar-wrapper active">
        <div class="sidebar-menu">
            <h3 class="blog-name pt-4 mb-0">
                <a class="no-text-decoration text-white" href="index.html">Raphael Soares</a>
            </h3>
            <div class="navbar navbar-expand-lg navbar-dark flex-column">
                <div class="profile-section pt-3 pt-lg-0">
                    <img
                        class="profile-image mb-3 rounded-circle mx-auto"
                        src="assets/images/sidebar-profile.jpeg"
                        alt="image"
                        style="max-width: 160px"
                    />

                    <div class="bio mb-3 small">
                        Olá, eu sou de Recife/PE, tenho {{ age }} anos e trabalho como {{ personalInfo.currentJob }}.
                        <br />
                        <br />
                        Bem-vindo ao meu site pessoal!
                    </div>
                    <!--//bio-->
                    <ul class="social-list list-inline py-2 mx-auto">
                        <li class="list-inline-item me-3">
                            <a href="https://www.linkedin.com/in/raphael-com-ph/" target="_blank">
                                <i class="fab fa-linkedin-in fa-fw"></i>
                            </a>
                        </li>
                        <li class="list-inline-item me-3">
                            <a href="https://github.com/raphaelcomph-dev" target="_blank">
                                <i class="fab fa-github-alt fa-fw"></i>
                            </a>
                        </li>
                        <li class="list-inline-item me-3">
                            <a role="button" [routerLink]="[URLS.PATHS.WHATSAPP()]">
                                <i class="fab fa-whatsapp fa-fw"></i>
                            </a>
                        </li>
                        <li class="list-inline-item me-3">
                            <a href="https://www.instagram.com/raphaelcomph.dev/" target="_blank">
                                <i class="fab fa-instagram fa-fw"></i>
                            </a>
                        </li>
                    </ul>
                    <!--//social-list-->
                    <hr />
                </div>

                <ul class="navbar-nav flex-column text-start">
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            [routerLink]="[URLS.PATHS.DEV.MAIN.HOME()]"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            ><i class="fas fa-user fa-fw me-2"></i>Sobre mim
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="[URLS.PATHS.DEV.MAIN.NOW()]" routerLinkActive="active">
                            <i class="fa-solid fa-person-walking fa-fw me-2"></i>
                            Em andamento
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="[URLS.PATHS.DEV.MAIN.PORTFOLIO.ROOT()]" routerLinkActive="active">
                            <i class="fas fa-laptop-code fa-fw me-2"></i>Portfólio</a
                        >
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="[URLS.PATHS.DEV.MAIN.PRICING_AND_SERVICES()]" routerLinkActive="active"
                            ><i class="fas fa-briefcase fa-fw me-2"></i> Serviços &amp; Orçamento</a
                        >
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="[URLS.PATHS.DEV.MAIN.RESUME()]" routerLinkActive="active"
                            ><i class="fas fa-file-alt fa-fw me-2"></i>Currículo</a
                        >
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="[URLS.PATHS.DEV.MAIN.BLOG()]" routerLinkActive="active"
                            ><i class="fas fa-blog fa-fw me-2"></i>Blog</a
                        >
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="[URLS.PATHS.DEV.MAIN.CONTACT()]" routerLinkActive="active">
                            <i class="fas fa-envelope-open-text fa-fw me-2"></i>Contato</a
                        >
                    </li>
                </ul>

                <div class="dark-mode-toggle text-center w-100">
                    <hr class="mb-4" />
                    <p class="small text-white-50">Última atualização realizada em: <br />18/08/2024 às 14:04</p>

                    <div class="d-flex align-items-center flex-column d-none">
                        <h6 class="toggle-name mb-3"><i class="fas fa-adjust me-1"></i>Modo Escuro</h6>
                        <div class="form-check form-switch fs-6">
                            <input class="form-check-input me-0" type="checkbox" id="toggle-dark" style="cursor: pointer" />
                            <label class="form-check-label"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

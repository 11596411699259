declare global {
    interface Array<T> {
        last(): T;
        takeFirst(n: number): T[];
    }
}

Array.prototype.last = function () {
    return this[this.length - 1];
};

Array.prototype.takeFirst = function (n: number = 1) {
    return this.slice(0, n);
};

export {};

<article class="resume-wrapper mx-auto bg-white p-5">
    <div class="resume-header">
        <div class="row align-items-center">
            <div class="resume-title col-12 col-md-6 col-lg-8 col-xl-9">
                <h2 class="mb-0 text-uppercase">Raphael Soares</h2>
                <div class="tagline mb-3 mb-md-0">Engenheiro de Software</div>
            </div>
            <!--//resume-title-->
            <div class="resume-contact col-12 col-md-6 col-lg-4 col-xl-3">
                <ul class="list-unstyled mb-0">
                    <li class="mb-2">
                        <i class="fab fa-whatsapp fa-fw fa-lg me-2"></i>
                        <a class="resume-link" href="https://wa.me/5581998212130">(81) 998 21 21 30</a>
                    </li>
                    <li class="mb-2">
                        <i class="fas fa-envelope fa-fw fa-lg me-2"></i>
                        <a class="resume-link" href="mailto:eu@raphael.com.ph">eu{{ "@" }}raphael.com.ph</a>
                    </li>
                    <li class="mb-2">
                        <i class="fas fa-globe fa-fw fa-lg me-2"></i>
                        <a class="resume-link" role="button">https://raphael.com.ph</a>
                    </li>
                    <li class="mb-0"><i class="fas fa-map-marker-alt fa-fw fa-lg me-1"></i> Recife/PE</li>
                </ul>
            </div>
            <!--//resume-contact-->
        </div>
        <!--//row-->
    </div>
    <!--//resume-header-->
    <hr />
    <div class="resume-intro py-3">
        <div class="row align-items-center">
            <div class="col-12 col-md-3 col-xl-2 text-center no-print">
                <img
                    class="mb-3 mb-md-0 me-md-5 ms-md-0 rounded-circle mx-auto"
                    src="assets/images/sidebar-profile.jpeg"
                    alt="image"
                    style="max-width: 120px"
                />
            </div>

            <div class="col text-start">
                <p class="">
                    Sou um engenheiro de software fullstack sênior, especializado em back-end, com pouco mais de 12 anos de
                    experiência. Já atuei em mais de 20 projetos dos mais diversos tamanhos e perfis.
                </p>
                <p>
                    Possuo conhecimentos sólidos em Java, Spring Framework, JavaScript, TypeScript, NodeJS, NestJS, Angular,
                    MongoDB e Postgres. Além disso, tenho habilidades em TDD, Clean Code, Continuous Integration e Continuous
                    Delivery, e tenho experiência em criar testes unitários, de Integração, de API e end-to-end tanto em código
                    legados como projetos greenfield.
                </p>
            </div>
            <!--//col-->
        </div>
    </div>
    <!--//resume-intro-->
    <hr />
    <div class="resume-body">
        <div class="row">
            <div class="resume-main col-12 col-lg-8 col-xl-9 pe-0 pe-lg-5">
                <section class="work-section py-3">
                    <h4 class="text-uppercase resume-section-heading mb-4">Experiência</h4>
                    <div class="item mb-3">
                        <div class="item-heading row align-items-center mb-2">
                            <h5 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Senior Java/JEE Developer</h5>
                            <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-start text-md-end">
                                e-Core | 2023.01 - Presente
                            </div>
                        </div>
                        <div class="item-content">
                            <p>
                                Iniciei em Junho de 2024 um outro projeto, onde estou atuando como Tech Lead junto com outros 2
                                desenvolvedores na criação de um portal para agendamento de carregamento de fertilizantes para a
                                Unifértil, uma empresa de produção de fertilizantes do RS.
                            </p>
                            <p>
                                Anteriormente participei do processo de modernização, atualização e implantação na nuvem das
                                aplicações da Priceline, uma gigante do turismo americano. Neste cliente, participei de 2 projetos
                                como desenvolvedor back-end utilizando Java, Postgres e Oracle.
                            </p>
                            <p>
                                O primeiro projeto durou cerca de 5 meses, onde precisávamos adicionar um novo processo a uma
                                aplicação que processava arquivos de pagamento, para incluir o processamento de arquivos de uma
                                nova empresa recentemente adquirida pelo nosso cliente.
                            </p>
                            <p>
                                O segundo projeto começou em junho de 2023 e durou 9 meses. Envolvendo a criação de um novo
                                sistema de orquestração de pagamentos para os novos fornecedores e vendedores do nosso cliente.
                            </p>
                            <p>Minhas responsabilidades atualmente são:</p>
                            <ul class="resume-list">
                                <li>
                                    Desenvolver e manter funcionalidades novas e antigas de acordo com os elevados padrões de
                                    qualidade esperados.
                                </li>
                                <li>Criar e automatizar testes unitários e testes de integração.</li>
                                <li>
                                    Implantação de novos recursos desenvolvidos em ambiente de controle de qualidade e produção
                                    usando feature flags.
                                </li>
                                <li>Monitorar, criar alertas e dar suporte a falhas em ambiente de produção.</li>
                                <li>Participar de reuniões para decisões arquiteturais de aplicações novas e antigas.</li>
                            </ul>
                        </div>
                    </div>
                    <!--//item-->
                    <div class="item mb-3">
                        <div class="item-heading row align-items-center mb-2">
                            <h5 class="item-title col-12 col-md-6 col-lg-7 mb-2 mb-md-0">
                                Engenheiro de Software Back-end Sênior
                            </h5>
                            <div class="item-meta col-12 col-md-6 col-lg-5 text-muted text-start text-md-end">
                                Thoughtworks | 2020.12 - 2022.12
                            </div>
                        </div>
                        <div class="item-content">
                            <p>
                                Participava de todo o ciclo de consultoria de entrega de software, desde ideação à implantação,
                                trabalhando principalmente na criação de sistemas distribuídos em larga escala a partir de
                                microsserviços para entregar software de alta qualidade, agregando valor para consumidores finais.
                            </p>
                            <p>
                                Eu participei de 3 projetos como desenvolvedor back-end. O primeiro foi para o cliente Natura e
                                durou 1 ano, onde criamos um portal de vendas para as consultoras Natura do Peru. Utilizamos
                                Typescript com Node, NestJS e Postgres como banco de dados.
                            </p>
                            <p>
                                O segundo projeto durou 5 meses com o cliente Cogna Educação, onde criamos um CRM customizado para
                                os professores do ensino superior da Cogna. Neste projeto utilizamos Typescript, Node com NestJS e
                                Mongoose e MongoDB como banco de dados.
                            </p>
                            <p>
                                O último projeto que participei durou 7 meses e foi pra o cliente Pizza Hut, onde reformulamos
                                todo o aplicativo e sistema de pedidos da Pizza Hut Brazil. Neste utilizamos Java com Spring Boot
                                v17, Postgres, RabbitMQ e GCP.
                            </p>
                            <p>Dentre as atribuições estavam:</p>
                            <ul class="resume-list">
                                <li>
                                    Parear com os desenvolvedores das clientes e da própria TW para desenvolvimento de novas
                                    funcionalidades nos produtos das clientes;
                                </li>
                                <li>
                                    mentorar, através de pareamento, colegas com menos experiência nas práticas ágeis, como TDD,
                                    Clean Code, Continous Integration, Continous Delivery, entre outras;
                                </li>
                                <li>Criar e automatizar testes unitários, de Integração, de API e end-to-end;</li>
                                <li>Criar e refatorar de testes de código legado em funcionalidades já existentes;</li>
                                <li>
                                    Participar de reuniões para tomadas de decisões técnicas e de negócio p/ decisão dos próximos
                                    incrementos nos produtos em desenvolvimento, além de reuniões para refinamento, escrita e
                                    estimativa de User Stories;
                                </li>
                                <li>
                                    Deploy das novas funcionalidades desenvolvidas no ambiente de produção usando feature toggles;
                                </li>
                                <li>Testes das User Stories desenvolvidas considerando os critérios de aceite.</li>
                            </ul>
                        </div>
                    </div>
                    <!--//item-->
                    <div class="item mb-3">
                        <div class="item-heading row align-items-center mb-2">
                            <h5 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Engenheiro de Software</h5>
                            <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-start text-md-end">
                                Pitang | 2017.10 - 2020.12
                            </div>
                        </div>
                        <div class="item-content">
                            <p>
                                Responsável por desenvolver novas funcionalidades, realizar testes unitários, realizar manutenções
                                corretivas e evolutivas, realizar code review e refactoring, participar de reuniões de
                                planejamento e estimativas de esforço, ajudar no esclarecimento das regras de negócio e nas
                                definições dos critérios de aceitação.
                            </p>
                            <p>
                                Participei de um total de 4 projetos. Utilizando Java foram 6 meses no AGFA-HealthCare e daí em
                                diante foi em .NET na SEE-PE, ONS-SAGER e Gavea Marketplace.
                            </p>
                        </div>
                    </div>
                    <!--//item-->
                    <div class="item">
                        <div class="item-heading row align-items-center mb-2">
                            <h5 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Outsystems Developer</h5>
                            <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-start text-md-end">
                                Nimbi | 2015.10 - 2017.10
                            </div>
                        </div>
                        <div class="item-content">
                            <p>
                                Usei Outsystems por 2 anos aprimorando o principal produto da Nimbi, um web app com objetivo de
                                gerenciar cadeira de suprimentos. Responsável por corrigir bugs, dar suporte a usuários em
                                produção e desenvolver novas funcionalidades relacionadas aos módulos de Compra (criação de
                                pedidos e requisições), Negocia (criação de leilões, propostas e cotações) e Catálogo (criação de
                                produtos e catálogos personalizados).
                            </p>
                        </div>
                    </div>
                    <!--//item-->
                    <div class="item">
                        <div class="item-heading row align-items-center mb-2">
                            <h5 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Engenheiro de Software Jr.</h5>
                            <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-start text-md-end">
                                Pitang | 2012.04 - 2015.10
                            </div>
                        </div>
                        <div class="item-content">
                            <p>
                                Responsável por desenvolver novas funcionalidades, realizar testes unitários e manutenções
                                corretivas e evolutivas, participar de reuniões de planejamento e estimativa de esforço.
                                Participei de 3 projetos cujos clientes foram Grupo Ser, SEFAZ-PE e Nimbi. Foi 2 anos na SEFAZ-PE
                                foi usando Java e 1 ano nos outros usando Outsystems.
                            </p>
                        </div>
                    </div>
                    <!--//item-->

                    <!--//item-->
                </section>
                <!--//work-section-->

                <section class="project-section py-3">
                    <h4 class="text-uppercase resume-section-heading mb-4">Projetos paralelos</h4>
                    <div class="item mb-3">
                        <div class="item-heading row align-items-center mb-2">
                            <h5 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Doula.app</h5>
                            <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-start text-md-end">
                                Projeto Pessoal
                            </div>
                        </div>
                        <div class="item-content">
                            <p>
                                Junto com uma grande amiga, idealizamos e criamos o Doula.app (<a
                                    href="http://doula.app"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >https://doula.app</a
                                >), primeiro App voltado para Doulas do Brasil. Este Web App se propõe a facilitar o gerenciamento
                                de gestantes das doulas e facilitar seu controle financeiro.
                            </p>
                        </div>
                    </div>
                    <div class="item mb-3">
                        <div class="item-heading row align-items-center mb-2">
                            <h5 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Federação Espírita Pernambucana</h5>
                            <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-start text-md-end">Pro bono</div>
                        </div>
                        <div class="item-content">
                            <p>
                                Desde 2020 até 2023, me engajei como voluntário no Departamento de Comunicação (DECOM) da
                                Federação Espírita Pernambucana (FEP). Fui responsável pela reformulação completa do layout site
                                oficial da Federação Espírita Pernambucana. Alguns dos requisitos do projeto foram a adaptação do
                                layout p/ um modelo responsivo e a modernização do design. Também fui responsável pelo
                                desenvolvimento do, até então inexistente, site da Mostra Espírita de Pernambuco.
                            </p>
                        </div>
                    </div>
                    <!--//item-->
                    <!--//item-->
                </section>
                <!--//project-section-->
            </div>
            <!--//resume-main-->
            <aside class="resume-aside col-12 col-lg-4 col-xl-3 px-lg-4 pb-lg-4 border-lg-start">
                <section class="skills-section py-3">
                    <h4 class="text-uppercase resume-section-heading mb-4">Habilidades</h4>
                    <div class="item">
                        <h5 class="item-title">Técnicas</h5>
                        <ul class="list-unstyled resume-skills-list">
                            <li class="mb-2">Node | NestJS | Jest | Mocha | Chai</li>
                            <li class="mb-2">Java | Spring Boot | JPA | Hibernate | JUnit | TestContainers</li>
                            <li class="mb-2">Javascript | Typescript | Angular</li>
                            <li class="mb-2">
                                MVC | Microsserviços | Arquitetura hexagonal | Arquitetura Limpa | Código Limpo | SOLID | Padrões
                                de Projetos | BFF | REST API | Swagger
                            </li>
                            <li class="mb-2">MongoDB | Mongoose | Sequelize | TypeORM | Postgres | SQLServer | Oracle</li>
                            <li class="mb-2">Google Cloud Platform | Docker</li>
                        </ul>
                    </div>
                    <!--//item-->
                    <div class="item">
                        <h5 class="item-title">Comportamentais</h5>
                        <ul class="list-unstyled resume-skills-list">
                            <li class="mb-2">Pensamento abstrato</li>
                            <li class="mb-2">Proatividade</li>
                            <li class="mb-2">Organização</li>
                            <li class="mb-2">Aprendizado rápido</li>
                            <li class="mb-2">Resolvedor de problemas</li>
                            <li class="mb-2">Feedback assertivo</li>
                        </ul>
                    </div>
                    <!--//item-->
                </section>
                <!--//skills-section-->
                <section class="education-section py-3">
                    <h4 class="text-uppercase resume-section-heading mb-4">Educação</h4>
                    <ul class="list-unstyled resume-education-list">
                        <li class="mb-3">
                            <div class="resume-degree font-weight-bold">Bacharel em Sistemas de Informação</div>
                            <div class="resume-degree-org text-muted">Universidade Federal Rural de Pernambuco (UFRPE)</div>
                            <div class="resume-degree-time text-muted">2010 - 2018</div>
                        </li>
                        <li>
                            <div class="resume-degree font-weight-bold">Curso de Inglês</div>
                            <div class="resume-degree-org text-muted">Serviço Nacional de Aprendizagem Comercial (SENAC)</div>
                            <div class="resume-degree-time text-muted">2010 - 2012</div>
                        </li>
                    </ul>
                </section>
                <!--//education-section-->
                <!--//education-section-->
                <section class="skills-section py-3">
                    <h4 class="text-uppercase resume-section-heading mb-4">Línguas</h4>
                    <ul class="list-unstyled resume-lang-list">
                        <li class="mb-2">Português <span class="text-muted">(Nativo)</span></li>
                        <li>Inglês <span class="text-muted">(Profissional)</span></li>
                    </ul>
                </section>
                <!--//certificates-section-->
                <!--//certificates-section-->
            </aside>
            <!--//resume-aside-->
        </div>
        <!--//row-->
    </div>
    <!--//resume-body-->
    <hr />
    <div class="resume-footer text-center">
        <ul class="resume-social-list list-inline mx-auto mb-0 d-inline-block text-muted">
            <li class="list-inline-item mb-lg-0 me-3">
                <a class="resume-link" href="https://github.com/raphaelcomph-dev">
                    <i class="fab fa-github-square fa-lg me-2 text-black-50"></i>
                    <span class="text-muted">github.com/raphaelcomph-dev</span>
                </a>
            </li>
            <li class="list-inline-item mb-lg-0 me-3">
                <a class="resume-link" href="https://linkedin.com/in/raphael-com-ph">
                    <i class="fab fa-linkedin fa-lg me-2 text-black-50"></i>
                    <span class="text-muted">linkedin.com/in/raphael-com-ph</span>
                </a>
            </li>
        </ul>
    </div>
    <!--//resume-footer-->
</article>

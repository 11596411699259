<section class="d-flex align-items-center p-3 p-lg-5">
    <div class="align-self-center mx-auto py-8 px-sm-10 px-5 bg-white">
        <div class="mx-auto p-sm-5 py-5" style="max-width: 620px">
            <div class="mb-5 text-center">
                <img
                    src="assets/images/storyset/under-construction.png"
                    alt=""
                    class="img-fluid w-100"
                    style="max-width: 400px"
                />
            </div>
            <div class="mb-3">
                <div class="text-center">
                    <h2 class="text-muted">Em construção</h2>
                </div>
            </div>

            <div class="text-center mb-2">
                <p>
                    Está seção ainda está em desenvolvimento. Em breve estará finalizada. Você pode acompanhar no que estou
                    trabalhando na página <a role="button" [routerLink]="URLS.PATHS.DEV.MAIN.NOW()">Em andamento</a>.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="bg-white py-5">
    <div class="container text-center single-col-max-width">
        <h2 class="heading fw-bold mb-4">Em andamento</h2>
        <div class="intro">
            <p>
                O intuito dessa página é compartilhar o que é que está rolando e no que estou focado no momento, tanto na vida
                profissional quanto na vida pessoal. Por questões de organização, preferi dividir as atualizações em períodos
                trimestrais.
            </p>
            <p>
                A inspiração pra a criação dessa página veio do
                <a href="https://nownownow.com/about" target="_blank" rel="noopener noreferrer">projeto now page</a>.
            </p>
        </div>
    </div>
    <!--//container-->
</section>

<section class="px-3 py-5 p-md-5">
    <div class="container single-col-max-width">
        <!-- loaded from remote url -->
        <markdown [src]="'/assets/data/now/now.md'" (load)="onLoad($event)" (error)="onError($event)"> </markdown>
    </div>
</section>

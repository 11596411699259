import { Component, OnInit } from "@angular/core";
import { BasePageView } from "../base-page.view";

@Component({
    selector: "drive-page",
    templateUrl: "./drive.page.html",
    styles: ``,
})
export class DrivePage extends BasePageView implements OnInit {
    ngOnInit(): void {
        window.location.href = this.URLS.LINKS.GOOGLE_DRIVE();
    }
}

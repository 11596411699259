<section class="bg-white py-5">
    <div class="container text-center single-col-max-width">
        <h2 class="heading mb-4">Contato</h2>
        <div class="intro">
            <p>
                Interessado em me chamar para um projeto freelancer, solicitar um orçamento, ou apenas mandar um Oi? Preenche o
                formulário aí embaixo ou envia um email para <a href="mailto:#">eu{{'@'}}raphael.com.ph</a>
            </p>
            <p>Meu compromisso é responder em no máximo 24h úteis.</p>
            <div class="d-none">
                <p>Quer se trocar uma ideia ou jogar conversa fora? É fácil me encontrar nas redes sociais.</p>
                <ul class="list-inline mb-0">
                    <li class="list-inline-item mb-3">
                        <a class="twitter" href="#"><i class="fab fa-twitter fa-fw fa-lg"></i> </a>
                    </li>

                    <li class="list-inline-item mb-3">
                        <a class="linkedin" href="#"><i class="fab fa-linkedin-in fa-fw fa-lg"></i> </a>
                    </li>
                    <li class="list-inline-item mb-3">
                        <a class="github" href="#"><i class="fab fa-github-alt fa-fw fa-lg"></i> </a>
                    </li>
                    <li class="list-inline-item">
                        <a class="instagram" href="#"><i class="fab fa-instagram fa-fw fa-lg"></i> </a>
                    </li>
                    <li class="list-inline-item mb-3">
                        <a class="stack-overflow" href="#"><i class="fab fa-stack-overflow fa-fw fa-lg"></i> </a>
                    </li>
                    <li class="list-inline-item mb-3">
                        <a class="medium" href="#"><i class="fab fa-medium-m fa-fw fa-lg"></i> </a>
                    </li>
                    <li class="list-inline-item mb-3">
                        <a class="codepen" href="#"><i class="fab fa-codepen fa-fw fa-lg"></i> </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<section class="contact-section px-3 py-5 p-md-5">
    <div class="container">
        <form id="contact-form" class="contact-form col-lg-8 mx-lg-auto" (ngSubmit)="onSubmit()">
            <h3 class="text-center font-weight-bold mb-4">Entre em contato</h3>

            <div class="row g-3">
                <div class="col-12 col-md-4">
                    <app-input-text
                        #inputName
                        placeholder="Nome"
                        [required]="true"
                        [modifier]="nameInputTextModifier"
                        [validators]="[nameInputTextValidator]"
                        [readOnly]="!isFormInEditableState(contactFormState)"
                    ></app-input-text>
                </div>
                <div class="col-12 col-md-4">
                    <app-input-text
                        #inputPhone
                        placeholder="Celular/Whatsapp"
                        type="tel"
                        [required]="true"
                        [mask]="PHONE_MASK"
                        [readOnly]="!isFormInEditableState(contactFormState)"
                    ></app-input-text>
                </div>
                <div class="col-12 col-md-4">
                    <app-input-text
                        #inputEmail
                        placeholder="Email"
                        [required]="true"
                        [validators]="[emailInputTextValidator]"
                        [readOnly]="!isFormInEditableState(contactFormState)"
                    ></app-input-text>
                </div>
                <div class="col-12">
                    <app-select-single-choice
                        #selectReason
                        placeholder="Selecione o motivo do contato"
                        [options]="contactReasonSelectOptions"
                        [required]="true"
                        [readOnly]="!isFormInEditableState(contactFormState)"
                    >
                    </app-select-single-choice>
                    <div class="mt-2">
                        <small class="form-text text-muted pt-1"
                            ><i class="fas fa-info-circle me-2 text-primary"></i> Quer saber mais sobre meus pacote de serviços?
                            Dá uma olhada na página de
                            <a class="text-link" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.PRICING_AND_SERVICES()"
                                >Serviços &amp; Orçamento</a
                            >.</small
                        >
                    </div>
                </div>
                <div class="col-12">
                    <app-input-text-area
                        #textAreaMessage
                        placeholder="Mensagem"
                        [required]="true"
                        [readOnly]="!isFormInEditableState(contactFormState)"
                    ></app-input-text-area>
                </div>
                <div class="form-group col-12">
                    <button
                        type="submit"
                        class="btn btn-block btn-primary py-2"
                        [disabled]="!isFormInEditableState(contactFormState)"
                    >
                        <span *ngIf="isFormInLoadingState(contactFormState)">
                            <span class="spinner-border spinner-border-sm me-3" aria-hidden="true"></span>
                            <span>Enviando...</span>
                        </span>
                        <span *ngIf="isFormInEditableState(contactFormState)">
                            <span>Enviar mensagem</span>
                        </span>
                        <span *ngIf="isFormInSuccessState(contactFormState)">
                            <span>Mensagem enviada!</span>
                        </span>
                    </button>
                </div>
            </div>
            <!--//form-row-->
        </form>
    </div>
    <!--//container-->
</section>

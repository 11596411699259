import { Component } from "@angular/core";
import { BaseView } from "../../../base.view";
import dayjs from "dayjs";
import { DataFileService } from "../../../../services/data-file.service";
import { DateService } from "../../../../services/date.service";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent extends BaseView {
    personalInfo: any = {};

    constructor(private readonly dataFileService: DataFileService, private readonly dateService: DateService) {
        super();
    }

    public get age(): number {
        return this.dateService.calculateAge(dayjs(this.personalInfo.birthDate).toDate()).years;
    }

    ngOnInit(): void {
        this.dataFileService.getPersonalInfo().subscribe((info) => {
            this.personalInfo = info;
        });
    }
}

<section class="p-3 p-lg-5 bg-white">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="fw-bold mb-1 display-5">Raphael Soares</h1>
                <h3 class="tagline mb-3">Engenheiro de Software</h3>
                <div class="bio mb-4">
                    Um recifense de {{ age }} anos que trabalha com programação há pouco mais de {{ workingAge }} anos. Eu rasbico
                    um pouco sobre desenvolvimento de software no
                    <a class="text-link" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.BLOG()">meu blog pessoal</a>. Quer saber
                    como eu posso te ajudar a tirar seus projetos do papel? Dá uma olhada no meu
                    <a class="text-link" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.RESUME()">currículo online</a> e em
                    alguns dos projetos que já desenvolvi no meu
                    <a class="text-link" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.PORTFOLIO.ROOT()">portfólio</a>.
                </div>
                <div class="mb-4">
                    <a class="btn btn-primary me-2 mb-3" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.PORTFOLIO.ROOT()">
                        <i class="fas fa-arrow-alt-circle-right me-2"></i>
                        <span class="d-none d-md-inline">Ver</span>
                        Portfólio
                    </a>
                    <a class="btn btn-secondary mb-3" role="button" [routerLink]="URLS.PATHS.DEV.MAIN.RESUME()">
                        <i class="fas fa-file-alt me-2"></i>
                        <span class="d-none d-md-inline"> Ver </span>
                        Currículo
                    </a>
                </div>
            </div>
            <!--//col-->

            <div class="col-md-5 col-lg-5">
                <img
                    class="profile-image img-fluid mb-3 mb-lg-0 me-md-0 w-100"
                    src="assets/images/hero.jpg"
                    style="max-width: 400px"
                    alt=""
                />
            </div>
        </div>
    </div>
</section>

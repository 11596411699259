import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppUrls } from "./app.urls";
import { WhatsappPage } from "./views/pages/whatsapp/whatsapp.page";
import { DrivePage } from "./views/pages/drive/drive.page";
import { BlogPostPage } from "./views/pages/dev/main/blog-post/blog-post.page";
import { BlogPage } from "./views/pages/dev/main/blog/blog.page";
import { ContactPage } from "./views/pages/dev/main/contact/contact.page";
import { HomePage } from "./views/pages/dev/main/home/home.page";
import { NowPage } from "./views/pages/dev/main/now/now.page";
import { PortfolioPage } from "./views/pages/dev/main/portfolio/portfolio.page";
import { PricingAndServicesPage } from "./views/pages/dev/main/pricing-and-services/pricing-and-services.page";
import { ProjectDetailsPage } from "./views/pages/dev/main/project-details/project-details.page";
import { ResumePage } from "./views/pages/dev/main/resume/resume.page";
import { DevMainComponent } from "./views/pages/dev/main/dev-main.component";
import { PrintableResumeComponent } from "./views/pages/dev/printable-resume/printable-resume.component";
import { SosPage } from "./views/pages/sos/sos.page";

const routes: Routes = [
    {
        path: AppUrls.PATHS.DEV.MAIN.ROOT(false),
        component: DevMainComponent,
        children: [
            { path: "", redirectTo: AppUrls.PATHS.DEV.MAIN.HOME(false), pathMatch: "full" }, // Default route
            { path: AppUrls.PATHS.DEV.MAIN.HOME(false), component: HomePage },
            { path: AppUrls.PATHS.DEV.MAIN.NOW(false), component: NowPage },
            { path: AppUrls.PATHS.DEV.MAIN.PORTFOLIO.ROOT(false), component: PortfolioPage },
            { path: AppUrls.PATHS.DEV.MAIN.PRICING_AND_SERVICES(false), component: PricingAndServicesPage },
            { path: AppUrls.PATHS.DEV.MAIN.RESUME(false), component: ResumePage },
            { path: AppUrls.PATHS.DEV.MAIN.BLOG(false), component: BlogPage },
            { path: AppUrls.PATHS.DEV.MAIN.CONTACT(false), component: ContactPage },
        ],
    },
    // { path: AppUrls.PATHS.ROOT(false), component: HomePage },
    // { path: AppUrls.PATHS.NOW(false), component: NowPage },
    // { path: AppUrls.PATHS.PRICING_AND_SERVICES(false), component: PricingAndServicesPage },
    // { path: AppUrls.PATHS.RESUME(false), component: ResumePage },
    // { path: AppUrls.PATHS.CONTACT(false), component: ContactPage },
    // { path: AppUrls.PATHS.PORTFOLIO.ROOT(false), component: PortfolioPage },
    // { path: AppUrls.PATHS.PORTFOLIO.PROJECT_DETAILS(false), component: ProjectDetailsPage },
    // { path: AppUrls.PATHS.BLOG(false), component: BlogPage },
    // { path: AppUrls.PATHS.BLOG_POST(false), component: BlogPostPage },
    { path: AppUrls.PATHS.RESUME(false), component: PrintableResumeComponent },
    { path: AppUrls.PATHS.WHATSAPP(false), component: WhatsappPage },
    { path: AppUrls.PATHS.DRIVE(false), component: DrivePage },
    { path: AppUrls.PATHS.SOS(false), component: SosPage },
    { path: "", redirectTo: AppUrls.PATHS.DEV.MAIN.ROOT(true), pathMatch: "full" }, // Default route for the entire application
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}

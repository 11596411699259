import { Component, OnInit } from "@angular/core";
import { BasePageView } from "../../base-page.view";

@Component({
    selector: "app-dev-main",
    templateUrl: "./dev-main.component.html",
    styles: ``,
})
export class DevMainComponent extends BasePageView implements OnInit {
    ngOnInit(): void {}
}

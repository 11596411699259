import { Component, Input } from "@angular/core";
import { BaseView } from "../../../base.view";

@Component({
    selector: "app-card-blog-post",
    templateUrl: "./card-blog-post.component.html",
    styleUrls: ["./card-blog-post.component.scss"],
})
export class CardBlogPostComponent extends BaseView {
    @Input() title: string;
    @Input() url: string;
    @Input() featureImageUrl: string;
    @Input() tags: any[];
    @Input() publishedAt: Date;
    @Input() updatedAt: Date;
}

import { Component, OnInit } from "@angular/core";
import { BasePageView } from "../base-page.view";

@Component({
    selector: "whatsapp-page",
    templateUrl: "./whatsapp.page.html",
    styles: ``,
})
export class WhatsappPage extends BasePageView implements OnInit {
    ngOnInit(): void {
        window.location.href = this.URLS.LINKS.WHATSAPP();
    }
}

import { Component } from "@angular/core";
import { BaseView } from "../../../base.view";
import dayjs from "dayjs";
import { DataFileService } from "../../../../services/data-file.service";
import { DateService } from "../../../../services/date.service";

@Component({
    selector: "app-home-section-skills",
    templateUrl: "./home-section-skills.component.html",
    styles: ``,
})
export class HomeSectionSkillsComponent extends BaseView {
    personalInfo: any = {};

    constructor(private readonly dataFileService: DataFileService) {
        super();
    }

    ngOnInit(): void {
        this.dataFileService.getPersonalInfo().subscribe((info) => {
            this.personalInfo = info;
        });
    }
}
